function setCookieJS(cname, cvalue, exdays, cdomain, csecure) {

  var d = new Date();
  var cookie_value;
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  cookie_value = cname + "=" + cvalue + ";" + expires + ";domain=" + cdomain + ";path=/";

  if (csecure == 'https') {
    cookie_value = (cookie_value + ";secure");
  }

  document.cookie = cookie_value;

}

function getCookieJS(cname) {

  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";

}

function checkCookieJS() {

  var user = getCookie("username");
  if (user != "") {
    alert("Welcome again " + user);
  } else {
    user = prompt("Please enter your name:", "");
    if (user != "" && user != null) {
      setCookie("username", user, 365);
    }
  }

}

function stopPropagation (event) {
  event.stopPropagation();
}

function toggleTooltips() {
  setTimeout(function() {
    $('[data-toggle="tooltip"]').tooltip();
  }, 5000);
}

// window.addEventListener("load", function() {
//   var dropdownHeaders = document.querySelectorAll(".dropdown-menu.main .dropdown-header");
//   console.log(1);

//   for (var i = 0; i < dropdownHeaders.length; i++) {
//     console.log(2);
//     dropdownHeaders[i].addEventListener("click", function() {
//       console.log(3);
//       document.querySelector(".dropdown-menu.main").classList.add("test");
//     });
//   }
// });